import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
// import shape from '../../../src/assets/Testimonial/shape-bg.png';
// import Typography from '@material-ui/core/Typography';
import "./Testimonial.css";

function Copyright() {
  return (
    // <Typography variant="body2" align="center" style={{ color: 'gray' }}>
    //   Copyright {'© '}
    //   {new Date().getFullYear()} Chris Bolosan
    // </Typography>
    <div className="copyright">
      <p style={{ color: "gray", textAlign: "center", fontSize: "0.875rem" }}>
        {"Copyright © "}
        {new Date().getFullYear()} Chris Bolosan
      </p>
    </div>
  );
}

export default function Testimonials(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  // const fadeInSubscription =
  ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const options = {
    loop: true,
    margin: 0,
    nav: true,
    animateIn: "bounceInRight",
    animateOut: "bounceOutRight",
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    //larger devices 3 columsn, smaller devices 1 column
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <div>
      <ScreenHeading
        title={"Recommendations"}
        subHeading={"What Others Say About Me"}
      />
      <section className="testimonial-section fade-in" id={props.id || ""}>
        <div className="container">
          <div className="row">
            <OwlCarousel
              className="owl-carousel"
              id="recommendations-carousel"
              {...options}
            >
              <div className="col-lg-12">
                <div className="rec-item">
                  <div className="rec-comment">
                    <p>
                      <i className="fa fa-quote-left" />
                      Chris is one of the most dedicated and diligent teammates
                      I've ever had the pleasure of working with. During our
                      time at Full Stack Academy, I was impressed by Chris's
                      grit and determination - especially when faced with an
                      unfamiliar or difficult problem. He is no stranger to hard
                      work and will always exceed expectations. Chris would be
                      an asset to any team, and his genuine, warm, and welcoming
                      personality is the icing on the cake!
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star-half-alt" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                  <div className="client-info">
                    <img
                      src="img/testimonial/mike.webp"
                      alt="no internet connection"
                    ></img>
                    <h5>Mike Alessi</h5>
                    <p>Senior Forward Deployed Engineer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="rec-item">
                  <div className="rec-comment">
                    <p>
                      <i className="fa fa-quote-left" />
                      Chris is a consummate professional. He is adept at all
                      aspects of the Software Development Life Cycle. He is
                      proficient with organizing, managing team dynamics, team
                      expectations, and team collaboration, and helps to foster
                      an environment that is inclusive and equitable. Chris has
                      advanced technical and troubleshooting skills and an
                      immense business acumen. I would recommend Chris for any
                      technical position and I believe he would excel in a
                      managerial position.
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star-half-alt" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                  <div className="client-info">
                    <img
                      src="img/testimonial/antonio.webp"
                      alt="no internet connection"
                    ></img>
                    <h5>Antonio Dinkins</h5>
                    <p>Cloud Engineer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="rec-item">
                  <div className="rec-comment">
                    <p>
                      <i className="fa fa-quote-left" />
                      Impressive is an understatement when talking about Chris.
                      His ability to communicate technical concepts in a
                      comprehensive manner, as well as apply his knowledge from
                      his prior work experience and combine it with the web
                      development skills he had learned over the course of the
                      17-week bootcamp was educational and inspiring. Chris’
                      command over his technical skills and knowledge make him
                      an excellent collaborator and an asset for any team of
                      developers.
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star-half-alt" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                  <div className="client-info">
                    <img
                      src="img/testimonial/michael.webp"
                      alt="no internet connection"
                    ></img>
                    <h5>Michael Orman</h5>
                    <p>AWS Solutions Architect</p>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
      <Copyright />
      {/* <div className="footer-image">
        <img src={shape} alt="not responding" style={{ width: '2600px' }} />
      </div> */}
    </div>
  );
}
