import './App.css';
// import Home from './PortfolioContainer/Home/Home';
import PortfolioContainer from './PortfolioContainer/PortfolioContainer';
import ReactGA from 'react-ga';
const TRACKING_ID = '291764904';
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div className="App">
      {/* <Home /> */}
      <PortfolioContainer />
    </div>
  );
}

export default App;
