import React from "react";
import Typical from "react-typical";
// import ScrollService from '../../../utilities/ScrollService';
import "./Profile.css";

// const handleHireMe = (e) => {
//   setHireMe(e.target.value)
// }
export default function Profile() {
  return (
    <div className="profile-container">
      <div className="profile-parent">
        <div className="profile-details">
          <div className="colz">
            <div className="cols-icon">
              <a
                href="https://github.com/chrisbolosan"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-github-square"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/chrisbolosan"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-linkedin-square"></i>
              </a>
              {/* <a href='#'>
                  <i className = 'fa fa-github-square'></i>
              </a> */}
            </div>
          </div>

          <div className="profile-details-name">
            <span className="primary-text">
              {" "}
              Hi, I'm <span className="highlighted-text">Chris </span>
            </span>
          </div>
          <div className="profile-details-role">
            <span className="primary-text">
              <h1>
                {" "}
                <Typical
                  loop={Infinity}
                  steps={[
                    "Software Engineer 😎",
                    1000,
                    // "Enthusiastic Dev ⚡️",
                    // 1000,
                    // "Full Stack Dev 💻",
                    // 1000,
                    "Freelancer",
                    1000,
                    // "Javascript ",
                    // 1000,
                    // "MERN Stack Dev 😎",
                    // 1000,
                    // "React/React Native 📱",
                    // 1000,
                  ]}
                ></Typical>
              </h1>
            </span>
            <span className="profile-role-tagline">
              Passionate coder, father, best colleague ever.
            </span>
          </div>
          <div className="profile-options">
            {/* <button
              className="btn primary-btn"
              onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
            >
              Hire Me{' '}
            </button> */}
            <a
              className="btn primary-btn"
              href="mailto:cbolosan@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Email Me
            </a>{" "}
            {/* <a
              href="Christopher Bolosan Resume.pdf"
              download="Christopher Bolosan Resume.pdf"
            >
              <button className="btn highlighted-btn">Get Resume</button>
            </a> */}
          </div>
        </div>
        <div className="profile-picture">
          <div className="profile-picture-background"></div>
        </div>
      </div>
    </div>
  );
}
