export default class Animations {
  static animations = new Animations();

  fadeInScreen = (screen_name) => {
    let screen = document.getElementById(screen_name);
    //check if screen doesnt exist
    if (!screen_name || !screen) return;

    //if xists, style it
    screen.style.opacity = '1';
    screen.style.transform = 'translateY(0px)';
  };
}
