import Home from "../PortfolioContainer/Home/Home";
import Blogs from "../PortfolioContainer/Blogs/Blogs";
import AboutMe from "../PortfolioContainer/AboutMe/AboutMe";
import Resume from "../PortfolioContainer/Resume/Resume";
import Testimonials from "../PortfolioContainer/Testimonial/Testimonials";
// import Pacman from "../PortfolioContainer/Games/Pacman";
// import ContactMe from '../PortfolioContainer/ContactMe/ContactMe';

export const TOTAL_SCREENS = [
  {
    screen_name: "Home",
    component: Home,
  },
  // {
  //   screen_name: "Pacman",
  //   component: Pacman,
  // },
  {
    screen_name: "AboutMe",
    component: AboutMe,
  },
  {
    screen_name: "Resume",
    component: Resume,
  },
  {
    screen_name: "Blogs",
    component: Blogs,
  },
  {
    screen_name: "Testimonials",
    component: Testimonials,
  },
  // {
  //   screen_name: 'Contact Me',
  //   component: ContactMe,
  // },
];

export const GET_SCREEN_INDEX = (screen_name) => {
  if (!screen_name) return -1;

  for (let i = 0; i < TOTAL_SCREENS.length; i++) {
    if (TOTAL_SCREENS[i].screen_name === screen_name) return i;
  }

  return -1;
};
